.refer-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.refer-modal-content {
    background: #216D69;
    border-radius: 15px;
    width: 80%;
    max-width: 600px;
    position: relative;
    overflow: hidden;
}

.refer-modal-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
}

.refer-modal-heading {
    font-size: 22px;
    color: #fff;
    font-weight: 600;
}

.refer-modal-close-btn {
    color: #fff;
    border-right: 1px solid #216D69;
    background-color: #216D69;
}

.refer-modal-close-btn:hover {
    cursor: pointer;
    color: #fff;
}

.refer-button {
    position: fixed;
    bottom: 36px;
    left: 25px;
    background-color: #4a4a4a;
    color: #FFFFFF;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 11;
}